import { PaymentMethod } from "@/api/sdk"
import Modal from "@/components/Modal"
import { paymentMethods } from "../UpgradeAccount"
import { Fragment } from "react"
import classNames from "classnames"
import { Radio } from "@/components/Alpha/View/GalleryFilter"
import IconButton from "@/components/IconButton"

const SelectPaymentMethodsModal = ({
  isOpen,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  isLoading = false,
  handleProcessPayment,
  onClose,
}: {
  isOpen: boolean
  isLoading: boolean
  selectedPaymentMethod: PaymentMethod
  setSelectedPaymentMethod: (id: PaymentMethod) => void
  handleProcessPayment: () => void | Promise<void>
  onClose: () => void
}) => {
  return (
    <Modal title={"Select payment method"} className="overflow-visible max-w-md" isOpen={isOpen} onClose={onClose}>
      <div className="bg-atherGray-900 p-2 md:p-4 rounded-2xl">
        <div className="flex flex-col space-y-4">
          {paymentMethods.map((method, index) => (
            <Fragment key={method.id}>
              <div
                className={classNames("py-2", {
                  "opacity-40 cursor-not-allowed": method.isDisabled,
                })}
              >
                <Radio
                  name={method.name}
                  labelClassName={"text-base font-semibold"}
                  containerClassName={classNames({
                    "cursor-not-allowed": method.isDisabled,
                  })}
                  value={method.id}
                  leftIcon={<img src={method.icon} alt="" className="w-8 h-8 ml-4" />}
                  checked={selectedPaymentMethod === method.id}
                  onChange={() => {
                    if (method.isDisabled) return
                    setSelectedPaymentMethod(method.id)
                  }}
                />
              </div>
              <hr className="border-atherGray-800" />
            </Fragment>
          ))}
          <IconButton isLoading={isLoading} onClick={handleProcessPayment} className="py-2">
            Process to payment
          </IconButton>
        </div>
      </div>
    </Modal>
  )
}

export default SelectPaymentMethodsModal
