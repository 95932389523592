import { SubscriptionPackageItem, WorkspaceDetail, WorkspacePlanItem } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { useToast } from "@/hooks"
import { cn } from "@/lib/utils"
import { useUnsubscribePlanMutate } from "@/queries"
import { User } from "firebase/auth"
import { Fragment, useState } from "react"
import { subscriptionPeriods } from "."
import UnsubscribeModal from "../Billing/UnsubscribeModal"
import ModelStoragePlanItem from "./ModelStoragePlanItem"

interface SubscriptionProps {
  listSubscription: SubscriptionPackageItem[]
  userPlan?: WorkspacePlanItem
  currentWorkspaceDetail?: WorkspaceDetail
  isLoadingCurrentWorkspace: boolean
  user: User | null
  userInfoQuery: any
  refetch?: () => void
}

const modelSubscriptionOrder = {
  model_storage_1: 1,
  model_storage_2: 2,
}

const ModelStorageSubscription = ({
  listSubscription,
  userPlan,
  user,
  userInfoQuery,
  refetch,
  currentWorkspaceDetail,
  isLoadingCurrentWorkspace,
}: SubscriptionProps) => {
  const [selectedTab, setSelectedTab] = useState("Monthly")
  const [modal, setModal] = useState<"UNSUBSCRIBE" | "CHANGE_METHOD" | "BILLING_INFO" | null>(null)
  const toast = useToast()

  const isTrial =
    userPlan?.isTrial === true && !!userPlan?.trialEndedAt && new Date(userPlan?.trialEndedAt) > new Date()

  const { mutate: mutateUnsubscribe, isPending: isMutatingUnsubscribe } = useUnsubscribePlanMutate({
    onSuccess: () => {
      refetch?.()
      setModal(null)
      toast({
        title: "Downgrade successfully",
        status: "success",
      })
    },
  })

  const handleUnsubscribe = () => {
    if (!userInfoQuery?.data?.currentWorkspace.id || !userPlan?.currentSubscription.id) return

    mutateUnsubscribe({
      data: {
        workspaceId: userInfoQuery?.data?.currentWorkspace.id,
        subscriptionPackageId: userPlan?.currentModelStorageSubscription?.subscriptionPackageId || "",
      },
      userUid: user?.uid ?? "",
    })
  }

  return (
    <Fragment>
      <div className="flex items-center justify-center mb-8">
        {/* tabs */}
        <div className="flex items-center justify-center space-x-2 p-1 border border-atherGray-800 rounded-full">
          {subscriptionPeriods.map((tab, index) => (
            <IconButton
              colorScheme="transparent"
              className={cn("bg-transparent py-1.5 px-3 text-xs md:text-base  whitespace-nowrap rounded-full", {
                "bg-atherGray-800 hover:bg-atherGray-800": tab.id === selectedTab,
              })}
              key={index}
              onClick={() => setSelectedTab(tab.id)}
            >
              {tab.id}
              {tab.sale && (
                <div className="ml-1 md:ml-2 bg-atherPurple-500 py-1 px-2 flex justify-center items-center font-semibold text-[#EAE4D4] rounded-lg relative">
                  <span
                    className="bg-atherPurple-500 absolute -left-[0.45rem] top-1/2 -translate-y-1/2 w-2 h-2 rotate-180"
                    style={{
                      clipPath: "polygon(80% 50%, 0 0, 0 100%)",
                    }}
                  />
                  <p className="text-[0.65rem] md:text-xs">Save {tab.sale}</p>
                </div>
              )}
            </IconButton>
          ))}
        </div>
      </div>

      {/* list subscription */}
      <div className="w-full">
        <div className="space-y-4 mb-12 px-2 md:px-0">
          {listSubscription.map(subscription => (
            <ModelStoragePlanItem
              key={subscription.id}
              subscription={subscription}
              tab={selectedTab}
              user={user}
              isActive={userPlan?.currentModelStorageSubscription?.subscriptionPackageId === subscription.id}
              isRequestCancel={!!userPlan?.currentModelStorageSubscription?.requestedCancel}
              currentWorkspace={currentWorkspaceDetail}
              shouldUnsubscribe={
                modelSubscriptionOrder[subscription.id] <
                modelSubscriptionOrder[userPlan?.currentModelStorageSubscription?.subscriptionPackageId || ""]
              }
              isLoadingCurrentWorkspace={isLoadingCurrentWorkspace}
              subscriptionCycle={userPlan?.currentModelStorageSubscription?.billingCycle}
              onUnsubscribe={() => setModal("UNSUBSCRIBE")}
            />
          ))}
        </div>
      </div>

      {/* unsubscribe modal */}
      <UnsubscribeModal
        isOpen={modal === "UNSUBSCRIBE"}
        title={"Downgrade to 50GB plan?!"}
        description={
          <>
            <p>
              The change will start on your next payment date. Until then, you can still use all the features of your
              current plan.
              <br />
              <br />
              Please note that downgrading your plan will not result in a refund for your current plan.
            </p>
          </>
        }
        confirmText={"Downgrade"}
        onClose={() => {
          setModal(null)
        }}
        footerContentClassName="hidden"
        onConfirm={() => handleUnsubscribe()}
        isLoading={isMutatingUnsubscribe}
      />
    </Fragment>
  )
}

export default ModelStorageSubscription
