import { clientUrl } from "@/constants"
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config"

import { createStorage } from "wagmi"
import { mainnet, sepolia, optimism, optimismSepolia, polygon, polygonAmoy } from "wagmi/chains"

// Get projectId at https://cloud.walletconnect.com
export const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID

if (!projectId) throw new Error("Project ID is not defined")

export const USING_TEST_NET = process.env.NEXT_PUBLIC_TEST_NET == "true"

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: clientUrl, // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
}

// Create wagmiConfig
const wagmiOptions = {}
const chains = USING_TEST_NET
  ? ([sepolia, optimismSepolia, polygonAmoy] as const)
  : ([mainnet, optimism, polygon] as const)
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  ssr: true,
  storage: createStorage({}),
  ...wagmiOptions, // Optional - Override createConfig parameters
})
