import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { cn } from "@/lib/utils"
import Link from "next/link"
import React from "react"

interface UnsubscribeModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isLoading?: boolean
  title?: string
  confirmText?: string
  description?: React.ReactNode
  footerContentClassName?: string
}

const UnsubscribeModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  title,
  footerContentClassName,
  description,
  confirmText,
}: UnsubscribeModalProps) => {
  return (
    <Modal className="max-w-md" title={title} isOpen={isOpen} onClose={onClose}>
      <div className="text-atherGray-300">{description}</div>
      <div className="mt-6 flex items-center space-x-2 justify-end">
        <IconButton onClick={onClose} colorScheme="secondary">
          Cancel
        </IconButton>
        <IconButton isLoading={isLoading} onClick={() => onConfirm()}>
          {confirmText}
        </IconButton>
      </div>
      <p className={cn("text-atherGray-500 text-center mt-4 text-xs", footerContentClassName)}>
        By continuing, you agree to GAIA&apos;s{" "}
        <Link href="/terms-of-service" target="_blank" className="underline text-atherGray-100">
          terms
        </Link>
      </p>
    </Modal>
  )
}

export default UnsubscribeModal
