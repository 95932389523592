import { useCallback } from "react"
import dynamic from "next/dynamic"
import { ProductType } from "@/api/sdk"
import CryptoPaymentModal from "@/components/ModalsPortal/CryptoPaymentModal"

const Payment2C2PModal = dynamic(() => import("../Setting/Plans/Payment2C2PModal"), { ssr: false })
const StripeCheckoutModal = dynamic(() => import("../Setting/Plans/StripeCheckoutModal"), { ssr: false })

interface PaymentModalsProps {
  paymentUrl: string | null
  stripeClientSecret: string | null
  cryptoPayment: { productType: ProductType; productId: string } | null
  onClose: () => void
  onSuccess?: () => void
}

export const PaymentModals = ({
  paymentUrl,
  stripeClientSecret,
  cryptoPayment,
  onClose,
  onSuccess,
}: PaymentModalsProps) => {
  const handleSuccess = useCallback(() => {
    onSuccess?.()
    onClose()
  }, [onSuccess, onClose])

  return (
    <>
      <Payment2C2PModal paymentUrl={paymentUrl} onClose={() => onClose()} />

      {cryptoPayment && (
        <CryptoPaymentModal cryptoPayment={cryptoPayment} onClose={onClose} onSuccess={handleSuccess} />
      )}

      {stripeClientSecret && (
        <StripeCheckoutModal clientSecret={stripeClientSecret} onClose={onClose} onSuccess={handleSuccess} />
      )}
    </>
  )
}
