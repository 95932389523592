export const currency = (
  value: number | string | undefined = 0,
  options?: {
    minimumFractionDigits?: number
    maximumFractionDigits?: number
    prefix?: string
    suffix?: string
    floor?: boolean
  },
) => {
  const opts = { minimumFractionDigits: 0, maximumFractionDigits: 2, prefix: '', suffix: '', floor: false, ...options }

  const power = Math.pow(10, opts.maximumFractionDigits || 2)
  const amount = opts.floor
    ? Math.round((typeof value === 'string' ? parseFloat(value) : value) * power) / power
    : typeof value === 'string'
    ? parseFloat(value)
    : value

  return `${opts.prefix}${(amount ?? 0).toLocaleString(undefined, opts)}${opts.suffix}`
}

export const symbolCurrency = (unit: string) => {
  switch (unit) {
    case 'USD':
      return '$'
    default:
      return ''
  }
}
