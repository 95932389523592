import { CreditPackageItem } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { cdnUrl } from "@/constants"
import { useScreen } from "@/hooks"
import { currency } from "@/utils"
import classNames from "classnames"
import React from "react"

interface CreditsProps {
  isPending: boolean
  paymentPackages?: CreditPackageItem[]
  selectedPackageId?: string
  onSelectPackage: (packId: string) => void
}

const CreditPackages = ({ isPending, paymentPackages, onSelectPackage }: CreditsProps) => {
  const { width } = useScreen()

  const isMobileOnly = width < 768

  return (
    <div className="w-full">
      <div className="space-y-4 px-2 md:px-0">
        {paymentPackages?.map(pack => (
          <div
            key={pack.id}
            className={classNames("bg-atherGray-850 p-4 rounded-2xl flex items-center", {
              "cursor-pointer": isMobileOnly,
            })}
            onClick={() => {
              if (!isMobileOnly) return
              onSelectPackage(pack.id)
            }}
          >
            <div className="rounded-full bg-atherPurple-300 w-6 h-6 md:w-8 md:h-8">
              <img src={`https://${cdnUrl}/resources/default-avatar.png`} alt="credit" className="w-full h-full" />
            </div>
            <div className="ml-4 flex-1">
              <div className="flex items-center">
                <h3 className="text-sm md:text-xl">
                  {currency(parseInt(pack.creditAmount), undefined, { isConvert: false })} $GAIA
                </h3>
                <div className="ml-2 bg-atherPurple-500 py-1 px-2 flex justify-center items-center font-semibold text-[#EAE4D4] rounded-lg relative">
                  <div
                    className="bg-atherPurple-500 absolute -left-[0.45rem] top-1/2 -translate-y-1/2 w-2 h-2 rotate-180"
                    style={{
                      clipPath: "polygon(80% 50%, 0 0, 0 100%)",
                    }}
                  />
                  <p className="text-[0.65rem] md:text-xs">+{parseFloat(pack.bonusPercentage) * 100}% bonus</p>
                </div>
              </div>
              <p className="text-xs text-atherGray-300 line-through">
                {currency(parseInt(pack.initialAmount), undefined, { isConvert: false })} $GAIA
              </p>
            </div>
            <h3 className="mx-0 md:mx-6 text-xl font-semibold">${pack.usdValue}</h3>
            <IconButton
              disabled={isPending}
              onClick={() => {
                onSelectPackage(pack.id)
              }}
              className="text-xs md:text-sm hidden md:flex"
            >
              Buy Now
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CreditPackages
