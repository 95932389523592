interface StripeCheckoutModalProps {
  clientSecret: string | null
  onClose: () => void
  onSuccess?: () => void
}

import Modal from "@/components/Modal"
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || "")

const StripeCheckoutModal = (props: StripeCheckoutModalProps) => {
  const { clientSecret, onSuccess: onComplete, onClose } = props

  return (
    <Modal
      isOpen={!!clientSecret}
      onClose={onClose}
      className="w-full max-w-screen-2xl h-full bg-white"
      closeButtonClassName="bg-atherGray-850"
    >
      <div id="checkout">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret, onComplete }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </Modal>
  )
}

export default StripeCheckoutModal
