import { SubscriptionPackageItem, WorkspaceDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { cdnUrl } from "@/constants"
import { useScreen } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import classNames from "classnames"
import { User } from "firebase/auth"

interface ModelStoragePlanItemProps {
  subscription: SubscriptionPackageItem
  shouldUnsubscribe: boolean
  tab: string
  currentWorkspace?: WorkspaceDetail
  user: User | null
  isLoadingCurrentWorkspace?: boolean
  isActive?: boolean
  isRequestCancel?: boolean
  subscriptionCycle?: string
  onUnsubscribe?: () => void
}

const ModelStoragePlanItem = ({
  subscription,
  tab,
  isRequestCancel,
  isActive,
  onUnsubscribe,
  currentWorkspace,
  user,
  isLoadingCurrentWorkspace,
  shouldUnsubscribe,
  subscriptionCycle,
}: ModelStoragePlanItemProps) => {
  const router = useCustomRouter()

  const { width } = useScreen()
  const isMobileOnly = width < 768
  const maxModelStorage = parseInt(subscription.maxModelStorage.toString()) / 1024 / 1024 / 1024
  const costPerMonth =
    tab === "Monthly"
      ? subscription.monthlyCost
      : tab === "Bi-Yearly"
        ? (parseInt(subscription.biYearlyCost) / 6).toFixed(0)
        : (parseInt(subscription.yearlyCost) / 12).toFixed(0)
  const isSameSubscriptionCycle = !!subscriptionCycle && tab.toLowerCase() !== subscriptionCycle
  const isDisabled =
    currentWorkspace?.owner?.uid !== user?.uid || isRequestCancel || isActive || isSameSubscriptionCycle

  const handleClick = async () => {
    if (shouldUnsubscribe) {
      onUnsubscribe?.()
    } else {
      router.push(`/settings/upgrade-account?subscriptionId=${subscription.id}&tab=${tab}`, undefined, {
        shallow: true,
        scroll: true,
      })
    }
  }

  return (
    <div
      key={subscription.id}
      className={classNames("bg-atherGray-900 p-4 rounded-2xl flex items-center", {
        "cursor-pointer": isMobileOnly,
      })}
      onClick={() => {
        if (!isMobileOnly) return

        handleClick()
      }}
    >
      <div className="rounded-full bg-atherPurple-300 w-6 h-6 md:w-8 md:h-8">
        <img src={`https://${cdnUrl}/resources/default-avatar.png`} alt="credit" className="w-full h-full" />
      </div>
      <div className="ml-4 flex-1">
        <div className="flex items-center">
          <h3 className="text-sm md:text-xl">{`${maxModelStorage} GB/workspace/month`}</h3>
        </div>
      </div>
      <h3 className="mx-0 md:mx-6 text-xl font-semibold">${costPerMonth}</h3>
      {isActive ? (
        <div className="text-center bg-atherGray-850 p-2 rounded-lg text-sm font-semibold text-atherGray-300">
          Current plan
        </div>
      ) : (
        <IconButton
          disabled={isDisabled}
          isLoading={isLoadingCurrentWorkspace}
          onClick={handleClick}
          className="text-xs md:text-sm hidden md:flex"
        >
          {isRequestCancel ? "Requested" : shouldUnsubscribe ? "Downgrade" : "Buy now"}
        </IconButton>
      )}
    </div>
  )
}

export default ModelStoragePlanItem
