import IconButton from "@/components/IconButton"
import Input from "@/components/Input"
import Modal from "@/components/Modal"
import { StarFillIcon, TickIcon } from "@/components/shared/icons"
import { useContactUsMutation } from "@/queries"
import React, { Fragment, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

type ContactUsForm = {
  name: string
  companyName: string
  companyFacingName: string
  goal: string
  communicateChannel: string
}

const CustomPlan = () => {
  const [isOpen, setIsOpen] = useState(false)

  const form = useForm<ContactUsForm>()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form

  const { mutate: mutateContactUs, isPending: isContactUsPending } = useContactUsMutation({
    onSuccess: () => {
      setIsOpen(false)
      form.reset()
    },
  })

  const submit = (data: ContactUsForm) => {
    mutateContactUs({
      challenge: data.companyFacingName,
      comChannel: data.communicateChannel,
      companyName: data.companyName,
      goal: data.goal,
      name: data.name,
    })
  }

  return (
    <Fragment>
      <div className="border-[2px] border-atherGray-800 w-full md:w-[17rem] rounded-2xl py-4">
        <div className="px-4">
          <IconButton
            onClick={() => setIsOpen(true)}
            className="mb-4 block text-center border bg-atherPurple-500 border-atherGray-800 w-full p-2 rounded-lg text-sm font-semibold"
          >
            Contact us
          </IconButton>
          <div className="text-atherGray-300 text-sm space-y-2">
            <div className="mb-8">
              <p className="font-semibold mb-2 text-whiteAlpha-600">Special Deals</p>
              <div className="flex mb-1 items-center">
                <StarFillIcon className="text-atherPurple-100" />
                <p className="ml-2 flex-1 font-semibold">Unlimited CREDITs</p>
              </div>
              <div className="flex items-center">
                <StarFillIcon className="text-atherPurple-100" />
                <p className="ml-2 flex-1 font-semibold">Storage: 30GB/ user</p>
              </div>
            </div>
            <p className="font-semibold mb-2 text-whiteAlpha-600">Includes</p>
            <div className="space-y-2">
              <div className="flex items-center">
                <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                  <TickIcon width={10} height={10} />
                </span>
                <p className="ml-2 flex-1">Add max 100 members</p>
              </div>
              <div className="flex items-center">
                <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                  <TickIcon width={10} height={10} />
                </span>
                <p className="ml-2 flex-1">Same features as the Advance Plan</p>
              </div>
              <div className="flex items-center">
                <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                  <TickIcon width={10} height={10} />
                </span>
                <p className="ml-2 flex-1">Enterprise-only integrations</p>
              </div>
              <div className="flex items-center">
                <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                  <TickIcon width={10} height={10} />
                </span>
                <p className="ml-2 flex-1">Train LoRA models and create the specific AI recipes</p>
              </div>
              <div className="flex items-center">
                <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                  <TickIcon width={10} height={10} />
                </span>
                <p className="ml-2 flex-1">Generate on-brand designs</p>
              </div>
              <div className="flex items-center">
                <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                  <TickIcon width={10} height={10} />
                </span>
                <p className="ml-2 flex-1">Dedicated Account Manager for commercial conversations</p>
              </div>
              <div className="flex items-center">
                <span className="bg-atherPurple-500 rounded-full w-4 h-4 flex items-center justify-center">
                  <TickIcon width={10} height={10} />
                </span>
                <p className="ml-2 flex-1">Training/workshop sessions</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title="Contact us" className="max-w-md">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(submit)}>
            <p className="text-atherGray-100 mb-4">A member of our team will be in touch soon</p>
            <div className="space-y-4">
              <div>
                <Input
                  label="Your Name"
                  {...register("name", { required: "This field is required" })}
                  placeholder="Enter"
                />
                {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>}
              </div>
              <div>
                <Input
                  label="Company Name"
                  {...register("companyName", { required: "This field is required" })}
                  placeholder="Enter"
                />
                {errors.companyName && <p className="text-red-500 text-xs mt-1">{errors.companyName.message}</p>}
              </div>
              <div>
                <Input
                  label="What current challenges is your company facing?"
                  {...register("companyFacingName", { required: "This field is required" })}
                  placeholder="Enter"
                />
                {errors.companyFacingName && (
                  <p className="text-red-500 text-xs mt-1">{errors.companyFacingName.message}</p>
                )}
              </div>
              <div>
                <Input
                  label="What specific goals are you hoping to achieve by using Generative AI?"
                  {...register("goal", { required: "This field is required" })}
                  placeholder="Enter"
                />
                {errors.goal && <p className="text-red-500 text-xs mt-1">{errors.goal.message}</p>}
              </div>
              <div>
                <Input
                  label="Preferred Communication Channel"
                  {...register("communicateChannel", { required: "This field is required" })}
                  placeholder="Enter"
                />
                {errors.communicateChannel && (
                  <p className="text-red-500 text-xs mt-1">{errors.communicateChannel.message}</p>
                )}
              </div>
            </div>
            <div className="flex items-center space-x-2 justify-end mt-6">
              <IconButton onClick={() => setIsOpen(false)} colorScheme="secondary">
                Cancel
              </IconButton>
              <IconButton isLoading={isContactUsPending} type="submit">
                Send
              </IconButton>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </Fragment>
  )
}

export default CustomPlan
